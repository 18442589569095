export default class SharedDiagramMySpaceContext
{
    constructor (){
        this.UID = '';
        this.EmailId = '';
        this.DiagramName = '';
        this.DiagramXml = '';
        this.SharedLink = '';
        this.DateTimeSaved = '';
    }
}