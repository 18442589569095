export default class WorkspaceDiagramContext
{
    constructor (){
        this.Id = '';
        this.EmailId = '';
        this.CollectionName = '';
        this.UID = '';
        this.DiagramName = '';
        this.DiagramJson = '';
        this.DateTimeSaved = '';
    }
}