export default class VMImage
{
    constructor()
    {
        this.DisplayName = '';
        this.SearcheableName = '';
        this.Publisher = '';
        this.PublisherSearchableName = '';
        this.Offer = '';
        this.Sku = '';
        this.Version = '';
    }
}